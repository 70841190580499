<template>
    <div class="app-wrapper">
        <el-container>
            <el-header>
                <navbar />
            </el-header>
            <el-container class="main-container">
                <el-aside width="240px">
                    <sidebar id="guide-sidebar" class="sidebar-container" />
                </el-aside>
                <el-main class="main-container">
                    <div class="box_404">
                        <img src="@/assets/attention.png" alt="">
                        <h3 class="subheading">A problem occurred while processing your request.</h3>
                        <p>We apologize for the inconvenience.</p>
                        <p class="redirect">You’ll be redirected or may proceed to the&nbsp;<span @click="gotoHome">Home
                                page</span>.
                        </p>

                    </div>
                    <!-- <app-main /> -->
                </el-main>

            </el-container>
            <el-footer>
                <Footer></Footer>
            </el-footer>

        </el-container>



    </div>
</template>

<script setup>
import { } from 'vue'
import AppMain from '@/layout/components/AppMain.vue'
import Navbar from '@/layout/components/Navbar.vue'
import Sidebar from '@/layout/components/Sidebar/index.vue'
import Footer from '@/layout/components/Footer.vue'
import { useRouter } from 'vue-router'
const router = useRouter()

function gotoHome() {
    router.push('/')
}
</script>

<style lang="scss" scoped>
.app-wrapper {
    height: 100%;

    .el-header {
        padding: 0;
    }

    .aw-top {
        display: flex;
        flex-direction: row;
    }

    .main-container {
        display: flex;
        flex-direction: row;
        padding: 0;
    }

    .el-footer {
        height: auto;
        padding: 0;
    }
}

.box_404 {
    width: 100%;
    height: 80vh;
    background-color: #10525e;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #02b6d6;
    line-height: 40px;

    span {
        cursor: pointer;
        color: #eaee03;
    }
}
</style>
